import React from 'react';
import Modal from 'react-modal';
import Markdown from 'markdown-to-jsx';

import cookies from '../components/footer/cookies';
import classes from './modalcookies.module.scss';

const CookiesModal = ({ userAcceptedCookieWarning, setUserAcceptedCookieWarning }) => {
  const customStyles = {
    overlay: {
      zIndex: 9999,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      boxShadow: '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2)',
    },
  };

  const acceptCookies = () => {
    setUserAcceptedCookieWarning(true);
  };

  return (
    <Modal isOpen={!userAcceptedCookieWarning} style={customStyles} contentLabel="cookies">
      <div className={classes.modalContainer}>
        <h2 className={classes.heading}>Heartspace Cookies Policy</h2>
        <Markdown className={classes.markdown}>{cookies}</Markdown>
        <div className={classes.acceptButtonContainer} onClick={acceptCookies}>
          <button className={classes.acceptButton}>Accept</button>
        </div>
      </div>
    </Modal>
  );
};

export default CookiesModal;
